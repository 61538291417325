import React from "react";

import Google from "assets/reviews/google.webp";
import Microsoft from "assets/reviews/microsoft.webp";
import Nvidia from "assets/reviews/nvidia.webp";
import Amazon from "assets/reviews/amazon.webp";
import Ikea from "assets/reviews/ikea.webp";
import MGM from "assets/reviews/unitednation.webp";

const Servicesicon = ({ footer_logo }) => {
  return (
    <div className="lg:flex  lg:justify-between  justify-center items-center gap-20 w-full md:px-0 px-4 ">
      <div className="text-[14px] text-white  font-opensans pt-6 pb-4 lg:w-max ">
        <p className="font-extrabold">Trusted by</p>

        <p className="font-normal w-max">Satisfied Clients Worldwide </p>
      </div>
      {footer_logo ? (
        <div
          className="w-full  flex justify-between items-center md:gap-12 gap-6 overflow-x-auto "
          style={{ scrollbarWidth: "none" }}
        >
          {footer_logo?.logo &&
            footer_logo?.logo.map((items, index) => (
              <div
                key={index}
                className="w-full text-white  flex justify-between items-center md:gap-12 gap-6 overflow-x-auto "
                style={{ scrollbarWidth: "none" }}
              >
                {" "}
                <img
                  src={items.url}
                  alt={items.alt_text}
                  className=" w-[95px] lg:w-[123px]"
                />
              </div>
            ))}
        </div>
      ) : (
        <div
          className="w-full  flex justify-between items-center md:gap-12 gap-6 overflow-x-auto "
          style={{ scrollbarWidth: "none" }}
        >
          <img src={Google} alt="google" className=" w-[79px] lg:w-[103px]" />
          <img
            src={Microsoft}
            alt="microsoft"
            className=" w-[95px] lg:w-[123px]"
          />
          <img src={Amazon} alt="amazon" className=" w-[95px] lg:w-[123px]" />
          <img src={Nvidia} alt="Adidas" className=" w-[82px] lg:w-[110px]" />
          <img src={Ikea} alt="Ikea" className="  lg:w-[82px] hidden lg:flex" />
          <img src={MGM} alt="MGM" className="  lg:w-[95px] hidden lg:flex" />
        </div>
      )}
    </div>
  );
};
export default Servicesicon;